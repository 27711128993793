import React from "react"
import { Row } from "antd"
import { graphql } from "gatsby"

import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import SharpHero from "../components/SharpHero/SharpHero"
import BlogList from "../components/BlogList/BlogList"

const description = `Check out all the lastest news, updates, and happenings here at The Residences at Biltmore. We'll be sure to keep you up to date on specials and promotions locally and here on property.`

const title = `Blog - The Residences at Biltmore`

export default ({ data, location }) => {
  const { hero } = data

  const heroProps = {
    caption: `Blog`,
    contentfulAsset: hero,
    imageStyle: { filter: `brightness(0.65)` },
  }

  const row = {
    align: "top",
    justify: "center",
    type: "flex",
    style: {
      margin: `auto`,
      maxWidth: 1360,
    },
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title={title}
        url={location.href}
      />
      <SharpHero {...heroProps} />
      <Row {...row}>
        <BlogList />
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "rab-exterior-red-sky" }) {
      ...HeroFluid
    }
  }
`
